import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { generateCustomGuitarUrl } from '../../lib/generateCustomGuitarUrl';
import UnderlinedButton from '../components/Button/UnderlinedButton';
import ProductCard from '../components/ProductCard';
import ProductsGrid from '../components/ProductsGrid';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import TestimonyCarouselGrid from '../components/TestimonyCarouselGrid';

const translations = [
  {
    locale: 'en',
    url: '/en/custom-made-guitars/',
  },
];

export default function GuitaresPersonnaliseesPage({ data }) {
  let {
    guitars: { nodes: guitars = [] },
  } = data;

  guitars = guitars.map((guitar) => ({
    name: guitar.name,
    image: {
      gatsbyImageData: guitar.featuredImage.asset.localFile.childImageSharp,
    },
    href: generateCustomGuitarUrl('fr', guitar.slug.current),
  }));

  return (
    <Layout translations={translations}>
      <article className="space-y-48">
        <PageHeader>
          Guitares <br />
          personnalisées
        </PageHeader>

        <section className="container">
          <Heading level={2}>Le processus</Heading>

          <div className="lg:columns-2">
            <Heading level={3} className="!mt-0">
              Le processus pour créer l’instrument de vos désirs
            </Heading>
            <div className="space-y-4">
              <p>
                Tout commence par une rencontre de 2 heures avec Michel dans son
                atelier. Entouré d’essence de bois de rose et d’épinette de
                Sitka, vous jaserez de ce qui vous fait tripper. Vous échangerez
                des anecdotes de show. Vous parlerez de vous, de votre passion
                pour la musique et les belles choses.
              </p>

              <p>
                Puis, vous entrerez dans le vif du sujet. Vous évoquerez votre
                préférence pour la sorte de bois. Vous décrirez la forme et le
                look. Et vous parlerez de la façon dont vous voulez que votre
                guitare sonne. Michel s’imprègnera de votre personnalité, il
                prendra des notes et vous vibrerez au même diapason.
              </p>

              <p>
                Ensuite, la création commence. Le luthier met son savoir-faire à
                l’œuvre. Tout au long du processus il vous envoie des photos de
                votre œuvre en construction. Selon la complexité du projet,
                Michel investira de 150 à 200 heures dans la confection de votre
                guitare sur-mesure.
              </p>
            </div>

            <Heading level={3}>
              Vous ferez un wow avant de faire un riff
            </Heading>
            <div className="space-y-4">
              <p>
                Vous n’oublierez jamais votre première Pellerin. Et vous en
                voudrez une deuxième.
              </p>

              <p>
                Parce que Michel Pellerin crée une guitare parfaite à vos yeux
                et à vos oreilles.
              </p>

              <p>
                Sur votre instrument, tout peut être personnalisé : chaque
                pièce, le bois, le manche, la couleur, le fini. Si vous avez à
                cœur de préserver la forêt, vous pouvez même choisir du bois
                recyclé fait à partir du fond forestier. C’est votre
                personnalité et vos goûts qui guident l’artisan. Chaque détail
                est réfléchi pour en faire une guitare unique pour vous.
              </p>

              <p>
                Lorsque l’œuvre est terminée, Michel part de son coin de pays
                québécois pour aller vous la livrer en personne. L’objet de
                collection se présente dans un magnifique étui qui peut aussi
                être personnalisé. Michel vous accompagnera dans ces moments de
                grand bonheur. Il fera les derniers ajustements pour que le son
                de votre Pellerin vous colle réellement à la peau.
              </p>
            </div>
          </div>
        </section>

        <StaticImage
          src="../images/PellerinGuitares_148.jpeg"
          alt=""
          role="presentation"
          className="w-screen"
        />

        <section className="container space-y-24">
          <div className="mx-auto max-w-prose text-center">
            <Heading level={3}>
              Un son international. Un&nbsp;savoir-faire québécois.
            </Heading>

            <p>
              Les expressions pour exprimer la pureté du son Pellerin sont
              nombreuses. Certains disent que leur guitare chante, d’autres la
              comparent à un grand piano. Une chose est sûre, jamais vous
              n’entendrez une guitare avec un son aussi riche et clair. Autant
              dans les basses, les médianes ou les aigües, la résonnance
              stupéfait les plus grands connaisseurs. Votre fréquence cardiaque
              augmentera dès que vous sentirez les fréquences de votre Pellerin.
            </p>
          </div>

          {/* <div>
            <WorldMap className="max-w-full" />
          </div> */}
        </section>

        <section className="container">
          <Heading level={2}>Les modèles de guitare</Heading>

          <ProductsGrid>
            {guitars.map((guitar) => (
              <ProductCard
                key={guitar.href}
                name={guitar.name}
                href={guitar.href}
                image={guitar.image}
              />
            ))}
          </ProductsGrid>
        </section>

        <section>
          <div className="container text-xl text-center">
            <Heading level={2}>
              Des clients chantent les louanges de leur Pellerin
            </Heading>
          </div>

          <TestimonyCarouselGrid className="my-12" />

          <div className="container text-center">
            <UnderlinedButton to="/temoignages" className="text-xl my-5">
              Lire les témoignages
            </UnderlinedButton>
          </div>
        </section>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query {
    centralImage: file(name: { eq: "PellerinGuitares_148" }) {
      childImageSharp {
        gatsbyImageData(width: 1536)
      }
    }
    guitars: allSanityGuitarModel(sort: { fields: orderRank, order: ASC }) {
      nodes {
        slug {
          current
        }
        name
        featuredImage {
          asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 744
                  height: 1044
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
  }
`;
